import React, { useEffect, useMemo, useState } from 'react';
import styles from '../Secure.module.scss';
import { useHeader } from '../../../context/HeaderContext';
import { UserListing } from './components/UserListing';
import { Methods, useFetch } from '../../../hooks/useFetch';
import { User } from '../../../context/AuthContext';
import { useParams } from 'react-router-dom';
import { ComponentLoader } from '../../../components/ComponentLoader';
import { Profile } from './Profile';

export interface UserListingItem extends User {
   followed: number;
}

export const SearchUsers: React.FC = () => {
   const { setConfig } = useHeader();
   const params = useParams();
   const [users, setUsers] = useState<Array<UserListingItem> | null>(null);

   const userId = params.id ? parseInt(params.id) : null;

   const { makeRequest: fetchUsers } = useFetch(`/api/users`, { method: Methods.GET });

   useEffect(() => {
      const handleFetchUsers = async () => {
         const { data: response } = await fetchUsers();

         setUsers(response.data);
      };

      handleFetchUsers();
   }, []);

   const { title, element, condition } = useMemo(() => {
      if (userId) {
         return {
            title: 'User',
            condition: true,
            hideSearchBar: false,
            element: <Profile />
         };
      }

      return {
         title: 'Users',
         hideSearchBar: false,
         condition: users !== null,
         element: (
            <div className={styles.searchUsersContent}>
               <UserListing users={users ? users : ([] as Array<UserListingItem>)} />
            </div>
         )
      };
   }, [users, userId]);

   useEffect(() => {
      setConfig({ title: title });
   }, [title]);

   return <div className={styles.searchUsersWrapper}>{condition ? element : <ComponentLoader isLoading />}</div>;
};
