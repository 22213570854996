import React from 'react';
import styles from '../Secure.module.scss';
import { CountryListingCard } from './CountryListingCard';
import { CountryLeague } from '../../../types/DbModels';

interface CountriesListingProps {
   countryLeagues: Array<CountryLeague>;
}

export const CountriesListing: React.FC<CountriesListingProps> = ({ countryLeagues = [] }) => {
   const renderCountryLeagues = () => {
      if (!countryLeagues.length) {
         return <div className={styles.listingNotFound}>Countries not found</div>;
      }

      return countryLeagues.map((cl: CountryLeague) => {
         return <CountryListingCard key={cl.id} {...cl} />;
      });
   };

   return (
      <div className={styles.countriesListingWrapper}>
         <div className={styles.browseCountriesListing}>{renderCountryLeagues()}</div>
      </div>
   );
};
