import React, { useState } from 'react';
import styles from '../../Secure.module.scss';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useAuth } from '../../../../context/AuthContext';
import { Methods, useFetch } from '../../../../hooks/useFetch';

interface UserListingCardProps {
   created_at: string | null | undefined;
   name: string | null | undefined;
   username: string | null | undefined;
   followed: number;
   id: number | null | undefined;
}

export const UserListingCard: React.FC<UserListingCardProps> = ({ created_at, name, username, id, followed }) => {
   const navigate = useNavigate();
   const [follow, setFollow] = useState<boolean>(Boolean(followed));
   const { user } = useAuth();

   const { makeRequest: handleFollow } = useFetch(`/api/users/follow`, {
      method: Methods.POST,
      body: { followed_id: id }
   });

   const handleFollowUser = async () => {
      setFollow(!follow);
      await handleFollow();
   };

   return (
      <div className={styles.searchUsersWidget}>
         <img
            src={'../assets/images/profile_picture.jpg'}
            loading="lazy"
            alt={`Profile pic`}
            className={styles.searchUsersPhoto}
            onClick={() => navigate(`/users/${id}`)}
         />
         <div className={styles.searchUsersInformation}>
            <div className={styles.searchUsersInformationSince}>
               {created_at ? moment(created_at).format('MMMM YYYY') : 'N/A'}
            </div>
            <div className={styles.searchUsersInformationName}>{name ? name : 'N/A'}</div>
            <div className={styles.searchUsersInformationCountry}>{username ? username : 'N/A'}</div>
         </div>
         <>
            {user?.id === id ? (
               <div className={styles.searchUsersFollowYou}>You</div>
            ) : (
               <IconButton size="large" onClick={handleFollowUser}>
                  {follow ? <Favorite style={{ color: '#ee843a' }} /> : <FavoriteBorder style={{ color: '#ee843a' }} />}
               </IconButton>
            )}
         </>
      </div>
   );
};
