import { ArrowBackIos } from '@mui/icons-material';
import React, { createContext, useState, useContext, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

interface HeaderConfig {
   title: string | null | undefined;
   icon1: ReactNode | null;
   icon2: ReactNode | null;
   icon1Click: (() => void) | null;
   icon2Click: (() => void) | null;
}

interface HeaderContextType {
   config: HeaderConfig | null;
   setConfig: (newConfig: Partial<HeaderConfig | null>) => void;
}

const HeaderContext = createContext<HeaderContextType | undefined>(undefined);

interface HeaderProviderProps {
   children: ReactNode;
}

export const HeaderProvider: React.FC<HeaderProviderProps> = ({ children }) => {
   const navigate = useNavigate();
   const [config, setConfig] = useState<HeaderConfig | null>({
      title: '',
      icon1: <ArrowBackIos />,
      icon2: null,
      icon1Click: () => navigate(-1),
      icon2Click: null
   });

   const updateConfig = (newConfig: Partial<HeaderConfig | null>) => {
      setConfig(() => ({
         ...{
            title: '',
            icon1: <ArrowBackIos />,
            icon2: null,
            icon1Click: () => navigate(-1),
            icon2Click: null
         },
         ...newConfig
      }));
   };

   return <HeaderContext.Provider value={{ config, setConfig: updateConfig }}>{children}</HeaderContext.Provider>;
};

export const useHeader = (): HeaderContextType => {
   const context = useContext(HeaderContext);
   if (!context) {
      throw new Error('useHeader must be used within a HeaderProvider');
   }
   return context;
};
