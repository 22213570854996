import React from 'react';
import styles from '../Secure.module.scss';
import { IconButton } from '@mui/material';
import { useHeader } from '../../../context/HeaderContext';
import { useNavigate } from 'react-router-dom';

export const Header: React.FC = () => {
   const { config } = useHeader();
   const navigate = useNavigate();

   if (!config) {
      return null;
   }

   return (
      <div className={styles.headerWrapper}>
         {config.icon1 && config.icon1Click ? (
            <IconButton onClick={config.icon1Click} sx={{ color: '#fff' }}>
               {config.icon1}
            </IconButton>
         ) : (
            <div></div>
         )}
         <img
            src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
            alt="BetBook Logo"
            className={styles.logo}
            style={{ justifySelf: 'center', alignSelf: 'center', cursor: 'pointer' }}
            onClick={() => navigate('/')}
         />
         <div className={styles.headerTitle}>{''}</div>
         {config.icon2 && config.icon2Click ? (
            <IconButton sx={{ color: '#fff' }} onClick={config.icon2Click}>
               {config.icon2}
            </IconButton>
         ) : (
            <div></div>
         )}
      </div>
   );
};
