import React, { useEffect, useState } from 'react';
import styles from '../Secure.module.scss';
import { GenericTab, Tabs } from '../components/Tabs';
import { Browse } from '../browse/Browse';
import { Favorites } from './components/Pages/Favorites/Favorites';
import { Top } from './components/Pages/Top/Top';
import { useNavigate, useParams } from 'react-router-dom';

const tabsConfiguation: Array<GenericTab> = [
   {
      id: 0,
      label: 'FAVORITES',
      href: 'favorites',
      element: <Favorites />
   },
   {
      id: 1,
      label: 'TOP',
      href: 'top',
      element: <Top />
   },
   {
      id: 2,
      label: 'ALL',
      href: 'all',
      element: <Browse />
   }
];

export const Matches: React.FC = () => {
   const params = useParams();
   const [selectedTab, setSelectedTab] = useState<GenericTab | null | undefined>(() => {
      if (params && params.tab) {
         const selectedTab = tabsConfiguation.find((tab: GenericTab) => tab.href === params.tab);

         if (selectedTab) {
            return selectedTab;
         }
      }

      return tabsConfiguation[0];
   });

   const navigate = useNavigate();

   useEffect(() => {
      if (selectedTab) {
         navigate(`/matches/${selectedTab.href}`);
      }
   }, []);

   const handleRenderContent = () => {
      if (!selectedTab) {
         return null;
      }

      return selectedTab.element;
   };

   return (
      <div className={styles.matchesWrapper}>
         <Tabs tabs={tabsConfiguation} updateParentChange={setSelectedTab} pageHref="/matches" />
         {handleRenderContent()}
      </div>
   );
};
