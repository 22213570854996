import React, { FC, useMemo } from 'react';
import styles from '../../Secure.module.scss';

interface FixtureDetailsCardProps {
   title: string;
   quote: number;
   selected: number;
   result: number | null;
   onClick: () => void;
   disabled: boolean;
   finished: boolean;
}

export const FixtureDetailsCard: FC<FixtureDetailsCardProps> = ({
   title,
   quote,
   selected,
   result,
   onClick,
   disabled,
   finished
}) => {
   const className = useMemo(() => {
      let tempClassName = `${styles.fixtureBiddingInformationSectionCard}`;

      if (selected && result === 1 && disabled) {
         return (tempClassName += ` ${styles.fixtureBiddingInformationSectionCardWon} `);
      }

      if (selected && result === 0 && disabled) {
         return (tempClassName += ` ${styles.fixtureBiddingInformationSectionCardLost} `);
      }

      if (!selected && result === 1 && disabled) {
         return (tempClassName += ` ${styles.fixtureBiddingInformationSectionCardCorrect} `);
      }

      if (selected && !finished) {
         return (tempClassName += ` ${styles.fixtureBiddingInformationSectionCardActive} `);
      }

      if (disabled) {
         return (tempClassName += ` ${styles.fixtureBiddingInformationSectionCardDisabled} `);
      }

      return tempClassName;
   }, [selected, result, disabled, finished]);

   return (
      <div onClick={disabled ? () => {} : onClick} className={className}>
         <div className={styles.fixtureBiddingInformationSectionCardTitle}>{title ? title : 'N/A'}</div>
         <div className={styles.fixtureBiddingInformationSectionCardQuote}>{quote ? quote : 'N/A'}</div>
      </div>
   );
};
