import React, { useState, useMemo } from 'react';
import styles from '../../Secure.module.scss';
import { UserListingCard } from './UserListingCard';
import { SearchBar } from '../../controls/SearchBar';
import { TablePagination } from '@mui/material';
import { UserListingItem } from '../SearchUsers';

interface UserListingProps {
   users: Array<UserListingItem>;
}

export const UserListing: React.FC<UserListingProps> = ({ users }) => {
   const [searchTerm, setSearchTerm] = useState('');
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);

   const filteredUsers = useMemo(() => {
      return users.filter((user) => user.username.toLowerCase().includes(searchTerm.toLowerCase()));
   }, [searchTerm, users]);

   const paginatedUsers = useMemo(() => {
      const start = page * rowsPerPage;
      return filteredUsers.slice(start, start + rowsPerPage);
   }, [page, rowsPerPage, filteredUsers]);

   const handlePageChange = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
   };

   if (!users.length) {
      return <div className={styles.userListingNotFound}>Users not found</div>;
   }

   return (
      <>
         <SearchBar
            className={styles.searchUsersSearch}
            searchTerm={searchTerm}
            searchLabel={'Search by username'}
            setSearchTerm={setSearchTerm}
         />
         <div className={styles.searchUsersListing}>
            {paginatedUsers.map((user, index) => (
               <UserListingCard
                  id={user.id}
                  username={user.username}
                  followed={user.followed}
                  created_at={user.created_at}
                  name={user.name}
                  key={index}
               />
            ))}
         </div>
         <TablePagination
            component="div"
            count={filteredUsers.length}
            className={styles.searchUsersPagination}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            labelRowsPerPage="Rows per page:"
         />
      </>
   );
};
