import React, { useEffect, useState } from 'react';
import styles from '../../../../Secure.module.scss';
import { Methods, useFetch } from '../../../../../../hooks/useFetch';
import { useNavigate, useParams } from 'react-router-dom';
import { ComponentLoader } from '../../../../../../components/ComponentLoader';
import { CollapsableLeagueCard } from '../../CollapsableLeagueCard';
import { FixtureDetails } from '../../../../fixture/FixtureDetails';
import { Fixture, League } from '../../../../../../types/DbModels';

export const Favorites: React.FC = () => {
   const [favoriteLeagues, setFavoriteLeagues] = useState<Array<League>>([]);
   const [fixtures, setFixtures] = useState<Array<Fixture>>([]);
   const [fixture, setFixture] = useState<Fixture | null | undefined>(null);
   const [isLoading, setIsLoading] = useState(true);

   const params = useParams();
   const navigate = useNavigate();

   const { makeRequest: fetchFavoriteLeagues } = useFetch(
      `/api/favorite/fixtures`,
      {
         method: Methods.GET
      },
      true
   );

   const handleRefetchLeague = async (leagueId: number | null = null) => {
      const { data: response } = await fetchFavoriteLeagues(leagueId ? { params: { id: leagueId } } : {});

      const leagueIndex = favoriteLeagues.findIndex((favoriteLeague: League) => favoriteLeague.id === leagueId);

      const newlyFetchedLeague: League = response.data.leagues[0];

      if (leagueIndex > -1) {
         newlyFetchedLeague.collapsed = favoriteLeagues[leagueIndex].collapsed;

         favoriteLeagues.splice(leagueIndex, 1);
      }

      setFavoriteLeagues([...favoriteLeagues, ...[newlyFetchedLeague]]);

      const newFixtures = fixtures.filter((fixture) => fixture.league_id !== leagueId);

      setFixtures([...newFixtures, ...response.data.fixtures]);

      setFixture(null);
   };

   const handleFetchFavoriteLeagues = async () => {
      const { data: response } = await fetchFavoriteLeagues();

      setFavoriteLeagues(response.data.leagues);
      setFixtures(response.data.fixtures);
      setIsLoading(false);
   };

   useEffect(() => {
      handleFetchFavoriteLeagues();
   }, []);

   useEffect(() => {
      if (params && params.id) {
         const currentFixture: Fixture | null | undefined = fixtures.find(
            (f: Fixture) => f.id === (params.id ? parseInt(params.id) : null)
         );

         if (currentFixture) {
            setFixture(currentFixture);
            return;
         }
      } else if (fixture) {
         handleRefetchLeague(fixture.league_id);
      }
   }, [params.id]);

   const handleUpdateFavoriteLeagues = (leagueToUpdate: League) => {
      const updatedLeagues = favoriteLeagues.map((league: League) => {
         if (league.id === leagueToUpdate.id) {
            return { ...league, collapsed: !league.collapsed };
         }

         return league;
      });

      setFavoriteLeagues(updatedLeagues);
   };

   const handleRenderFavoriteLeagues = () => {
      if (isLoading) {
         return <ComponentLoader isLoading />;
      }

      if (!favoriteLeagues.length) {
         return (
            <div
               onClick={() => navigate(`/matches/all`)}
               style={{
                  textAlign: 'center',
                  fontSize: '20px',
                  color: '#FFF',
                  fontWeight: 'bold'
               }}
            >
               You have no favorite leagues, click to start journey!
            </div>
         );
      }

      if (fixture) {
         return <FixtureDetails fixture={fixture} />;
      }

      return (
         <>
            {favoriteLeagues.map(
               (
                  { logo, name, fixtures_count, country_name, favorite, id, fixtures, collapsed }: any,
                  index: number
               ) => {
                  return (
                     <CollapsableLeagueCard
                        key={index}
                        id={id}
                        favorite={Boolean(favorite)}
                        collapsed={collapsed}
                        updateParent={handleUpdateFavoriteLeagues}
                        logo={logo}
                        name={name}
                        fixtures_count={fixtures_count}
                        fixtures={fixtures}
                        country_name={country_name}
                     />
                  );
               }
            )}
         </>
      );
   };

   return <div className={styles.favoritesWrapper}>{handleRenderFavoriteLeagues()}</div>;
};
