import React, { useState } from 'react';
import { IconButton, Collapse } from '@mui/material';
import { ExpandMore, ExpandLess, Star, StarBorder } from '@mui/icons-material';
import styles from '../../Secure.module.scss';
import { useFetch, Methods } from '../../../../hooks/useFetch';
import { useAuth } from '../../../../context/AuthContext';
import { Fixture } from '../../../../types/DbModels';
import { FixtureListingCard } from '../../browse/FixtureListingCard';

interface CollapsableCardProps {
   id: number;
   logo: string | null;
   name: string | null;
   fixtures_count: number | null;
   country_name: string | null;
   favorite: boolean;
   fixtures: Array<any>;
   collapsed: boolean;
   updateParent: (league: any) => void;
}

export const CollapsableLeagueCard: React.FC<CollapsableCardProps> = ({
   id,
   logo,
   name,
   fixtures_count,
   country_name,
   favorite,
   fixtures,
   collapsed,
   updateParent
}) => {
   const { user } = useAuth();
   const [isFavorite, setIsFavorite] = useState<boolean>(favorite);
   const [open, setOpen] = useState<boolean>(collapsed);

   const { makeRequest: setFavorite } = useFetch(
      `/api/leagues/favorite`,
      {
         method: Methods.POST,
         body: { league_id: id, user_id: user?.id }
      },
      true
   );

   const handleFavoriteClick = async () => {
      await setFavorite();
      setIsFavorite((isFavorite) => !isFavorite);
   };

   const toggleCollapse = () => {
      setOpen(!open);
      updateParent({
         id,
         collapsed
      });
   };

   const renderFixtures = () => {
      if (!fixtures.length) {
         return (
            <div
               className={styles.favoriteCollapseLeagueCardWidgetCountry}
               style={{
                  fontSize: '20px',
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                  letterSpacing: '3px'
               }}
            >
               No fixtures found!
            </div>
         );
      }

      return fixtures.map((fixture: Fixture, index: number) => {
         return <FixtureListingCard key={index} fixture={fixture} />;
      });
   };

   return (
      <div className={styles.favoriteColleapseLeagueCard}>
         <div className={styles.leagueCardHeader} style={{ zIndex: 1 }} onClick={toggleCollapse}>
            <img
               src={logo ? `${process.env.REACT_APP_API_URL}/api/static${logo}` : ''}
               loading="lazy"
               alt={`Logo for ${name}`}
               className={styles.browseCountryListingWidgetFlag}
            />
            <div className={styles.favoriteCollapseLeagueCardWidgetInformation} style={{ gridTemplateRows: 'auto' }}>
               <div className={styles.favoriteCollapseLeagueCardWidgetFixtures}>{country_name}</div>
               <div className={styles.favoriteCollapseLeagueCardWidgetCountry}>{name}</div>
               <div className={styles.favoriteCollapseLeagueCardWidgetFixtures}>{fixtures_count} matches</div>
            </div>
            <IconButton
               onClick={(e) => {
                  e.stopPropagation();
                  handleFavoriteClick();
               }}
               sx={{ color: '#fb8c00', zIndex: 2 }}
            >
               {isFavorite ? <Star /> : <StarBorder />}
            </IconButton>
            <IconButton onClick={toggleCollapse} sx={{ color: '#fb8c00', zIndex: 2, maxWidth: 50, justifySelf: 'end' }}>
               {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
         </div>
         <Collapse in={open} timeout="auto" sx={{ zIndex: 2 }} unmountOnExit>
            <div className={styles.fixturesList}>{renderFixtures()}</div>
         </Collapse>
      </div>
   );
};
