import React, { FC } from 'react';
import styles from '../../Secure.module.scss';
import { BidGames, Ticket } from '../FixtureDetails';
import { FixtureDetailsCard } from './FixtureDetailsCard';

interface FixtureDetailsSectionProps {
   key: string;
   title: string;
   children: Array<BidGames>;
   columns: number;
   handleTipClick: (game: BidGames) => void;
   ticket: Ticket;
   biddable: boolean;
   finished: boolean;
}

export const FixtureDetailsSection: FC<FixtureDetailsSectionProps> = ({
   title,
   columns,
   children,
   handleTipClick,
   ticket,
   biddable,
   finished
}) => {
   return (
      <div className={styles.fixtureBiddingInformationSection}>
         <div className={styles.fixtureBiddingInformationSectionTitle}>{title}</div>
         <div
            className={styles.fixtureBiddingInformationSectionContent}
            style={{
               gridTemplateColumns: `repeat(${columns}, 1fr)`
            }}
         >
            {children.map((game: BidGames) => {
               return (
                  <FixtureDetailsCard
                     title={game.title}
                     quote={game.quote}
                     key={game.key}
                     selected={ticket[game.key]}
                     result={game.result}
                     onClick={() => handleTipClick(game)}
                     disabled={!biddable || !game.quote}
                     finished={finished}
                  />
               );
            })}
         </div>
      </div>
   );
};
