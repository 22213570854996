import { createContext, useContext, useState } from 'react';

interface AuthContextState {
   isAuthenticated: boolean;
   setIsAuthenticated: (isAuthenticated: boolean) => void;
   authToken: string | null;
   setAuthToken: (authToken: string | null) => void;
   user: User | null;
   setUser: (user: User | null) => void;
}

export interface User {
   id: number;
   email: string;
   username: string;
   name: string | null;
   public: boolean;
   created_at?: string;
   country?: string;
}

const AuthContext = createContext<AuthContextState | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
   const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
   const [authToken, setAuthToken] = useState<string | null>(null);
   const [user, setUser] = useState<User | null>(null);

   return (
      <AuthContext.Provider
         value={{
            isAuthenticated,
            setIsAuthenticated,
            authToken,
            setAuthToken,
            user,
            setUser
         }}
      >
         {children}
      </AuthContext.Provider>
   );
};

export const useAuth = (): AuthContextState => {
   const context = useContext(AuthContext);
   if (!context) {
      throw new Error('useAuth must be used within a AuthProvider');
   }
   return context;
};
