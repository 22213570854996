import React, { ReactNode, useEffect, useState } from 'react';
import styles from '../Secure.module.scss';
import { Tabs as CustomTabs, Tab } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

interface TabsProps {
   tabs: Array<GenericTab>;
   updateParentChange: (tab: GenericTab) => void;
   pageHref: string;
}

export type GenericTab = {
   id: number;
   label: string | null;
   href: string;
   element: ReactNode;
};

export const Tabs: React.FC<TabsProps> = ({ tabs = [], updateParentChange, pageHref = '' }) => {
   const [selectedTab, setSelectedTab] = useState<GenericTab | null | undefined>(tabs[0]);
   const navigate = useNavigate();
   const params = useParams();
   const location = useLocation();

   const handleSetSelectedTab = (selectedTab: string) => {
      const currentTab = tabs.find((tab: GenericTab) => tab.id.toString() == selectedTab) as GenericTab;
      setSelectedTab(currentTab);
      updateParentChange(currentTab);
   };

   useEffect(() => {
      if (params && params.hasOwnProperty('tab')) {
         const tabValue = params.tab;

         const currentTab = tabs.find((tab: GenericTab) => tab.href === tabValue);

         if (currentTab) {
            setSelectedTab(currentTab);
            updateParentChange(currentTab);
            return;
         }
      }
   }, [params]);

   return (
      <div className={styles.customTabs}>
         <CustomTabs
            value={selectedTab?.id}
            onChange={(_event, newSelectedTab) => handleSetSelectedTab(newSelectedTab)}
            sx={{
               '.MuiTabs-indicator': {
                  background: 'linear-gradient(180deg, #ee843a 0%, #ffab3a 100%)'
               },
               '.MuiTab-root': {
                  color: '#CCCCCC',
                  fontSize: '16px',
                  letterSpacing: '3px',
                  '&.Mui-selected': {
                     color: '#FFF',
                     fontSize: '16px',
                     letterSpacing: '3px'
                  }
               }
            }}
         >
            {tabs.map(({ id, label, href }: GenericTab, index: number) => {
               return (
                  <Tab key={index} onClick={() => navigate(`${pageHref}/${href}`)} id={id.toString()} label={label} />
               );
            })}
         </CustomTabs>
         <></>
      </div>
   );
};
