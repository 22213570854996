import React, { useEffect, useState } from 'react';
import styles from '../../../Secure.module.scss';
import { Methods, useFetch } from '../../../../../hooks/useFetch';
import { useNavigate, useParams } from 'react-router-dom';
import { ComponentLoader } from '../../../../../components/ComponentLoader';
import { CollapsableWallCard } from '../CollapsableWallCard';
import { Fixture } from '../../../../../types/DbModels';
import { FixtureDetails } from '../../../fixture/FixtureDetails';
import { Button } from '@mui/material';
import moment from 'moment';

export interface WallPageProps {
   tab: 'my' | 'followed' | 'all';
}

export const WallPage: React.FC<WallPageProps> = ({ tab }) => {
   const [matches, setMatches] = useState<Array<Fixture>>([]);
   const [match, setMatch] = useState<Fixture | null>(null);
   const [bids, setBids] = useState<Array<Fixture>>([]);
   const [bid, setBid] = useState<Fixture | null | undefined>(null);
   const [isLoading, setIsLoading] = useState(true);
   const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));

   const params = useParams();
   const navigate = useNavigate();

   const { makeRequest: fetchMatches } = useFetch(
      `/api/wall/${tab}`,
      {
         method: Methods.GET
      },
      true
   );

   const handleFetchMatches = async () => {
      const { data: response } = await fetchMatches({ params: { startDate: startDate } });

      setMatches(response.data.matches);
      setBids(response.data.bids);
      setIsLoading(false);
   };

   const handleRefetchMatch = async (matchId: number | null = null) => {
      const { data: response } = await fetchMatches(matchId ? { params: { id: matchId, startDate: startDate } } : {});

      const matchIndex: number | null | undefined = matches.findIndex((match: Fixture) => match.id === matchId);

      const newlyFetchedMatch: Fixture = response.data.matches[0];

      if (matchIndex > -1) {
         newlyFetchedMatch.collapsed = matches[matchIndex].collapsed;

         matches.splice(matchIndex, 1);
      }

      const updatedMatches = [...matches, newlyFetchedMatch];

      updatedMatches.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

      setMatches(updatedMatches);

      const newBids = bids.filter((bid: any) => bid.match_id !== matchId);

      setBids([...newBids, ...response.data.bids]);

      setMatch(null);
   };

   useEffect(() => {
      if (params && params.id) {
         const currentFixture: Fixture | null | undefined = matches.find(
            (f: Fixture) => f.id === (params.id ? parseInt(params.id) : null)
         );

         if (currentFixture) {
            setMatch(currentFixture);
            return;
         }
      } else if (match) {
         handleRefetchMatch(match.id);
      }
   }, [params.id]);

   const handleSyncPrevious = () => {
      setStartDate(moment(startDate).subtract(1, 'day').format('YYYY-MM-DD'));
   };

   useEffect(() => {
      if (startDate) {
         handleFetchMatches();
      }
   }, [startDate]);

   const handleUpdateMatches = (matchToUpdate: Fixture) => {
      const updatedMatches = matches.map((match: Fixture) => {
         if (match.id === matchToUpdate.id) {
            return { ...match, collapsed: !match.collapsed };
         }

         return match;
      });

      setMatches(updatedMatches);
   };

   const handleRenderMatches = () => {
      if (isLoading) {
         return <ComponentLoader isLoading />;
      }

      if (!matches.length) {
         return (
            <div
               onClick={() => navigate(`/wall/all`)}
               style={{
                  textAlign: 'center',
                  fontSize: '20px',
                  color: '#FFF',
                  fontWeight: 'bold'
               }}
            >
               You have no bids, see others bids!
            </div>
         );
      }

      if (match) {
         return <FixtureDetails fixture={match} />;
      }

      if (bid) {
         // return <FixtureDetails fixture={fixture} />;
      }

      return (
         <>
            {matches.map((match: Fixture, index: number) => {
               return (
                  <CollapsableWallCard
                     key={index}
                     fixture={match}
                     collapsable={tab !== 'my'}
                     updateParent={handleUpdateMatches}
                  />
               );
            })}
         </>
      );
   };

   return (
      <div className={styles.favoritesWrapper}>
         <div style={{ display: 'grid', justifyContent: 'end', width: '98%' }}>
            <Button sx={{ justifySelf: 'end', alignSelf: 'center', color: '#FFF' }} onClick={handleSyncPrevious}>
               Sync previous
            </Button>
         </div>
         {handleRenderMatches()}
      </div>
   );
};
