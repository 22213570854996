import React, { useState } from 'react';
import { Collapse } from '@mui/material';
import styles from '../../Secure.module.scss';
import { Fixture, FixtureBid, PlayedBid } from '../../../../types/DbModels';
import { FixtureListingCard } from '../../browse/FixtureListingCard';
import moment from 'moment';

interface CollapsableCardProps {
   fixture: Fixture;
   updateParent: (league: any) => void;
   collapsable?: boolean;
}

export const CollapsableWallCard: React.FC<CollapsableCardProps> = ({ fixture, updateParent, collapsable = true }) => {
   const [open, setOpen] = useState<boolean>(Boolean(fixture.collapsed));

   const toggleCollapse = () => {
      setOpen(!open);
      updateParent({
         id: fixture.id,
         collapesed: fixture.collapsed
      });
   };

   const renderBids = () => {
      const bids = fixture.bids;

      if (!bids?.length) {
         return (
            <div
               className={styles.favoriteCollapseLeagueCardWidgetCountry}
               style={{
                  fontSize: '20px',
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                  letterSpacing: '3px'
               }}
            >
               No bids found!
            </div>
         );
      }

      return bids.map((bid: FixtureBid, index: number) => {
         return (
            <div
               key={index}
               className={styles.fixturesFinishedWrapper}
               style={{
                  borderRadius: '15px',
                  width: '98%',
                  justifySelf: 'center',
                  border: '1px solid #47b0e3',
                  background: '#122f3f'
               }}
            >
               <div style={{ fontSize: '16x', color: '#FFF', letterSpacing: '0px' }}>User : {bid.username}</div>
               <div style={{ fontSize: '14px', color: '#FFF' }}>
                  Bet placed at : {moment(bid.bet_placed).format('YYYY-MM-DD HH:mm:ss')}
               </div>
               <div className={styles.fixturesFinishedWrapperBids}>
                  {bid.playedBids?.map((playerBid: PlayedBid, index: number) => {
                     const className = `${styles.fixturesFinishedWrapperBid} ${styles.fixturesFinishedWrapperSelected}`;

                     return playerBid.label && playerBid.quota ? (
                        <div className={className} key={index}>
                           <div className={styles.fixturesFinishedWrapperBidLabel}>{playerBid.label}</div>
                           <div className={styles.fixturesFinishedWrapperBidQuote}>{playerBid.quota}</div>
                        </div>
                     ) : (
                        <div key={index}></div>
                     );
                  })}
               </div>
            </div>
         );
      });
   };

   return (
      <div className={styles.favoriteColleapseLeagueCard}>
         <div className={styles.matchCardHeader} style={{ zIndex: 1 }}>
            <FixtureListingCard
               fixture={fixture}
               collapsable={collapsable}
               expanded={open}
               toggleCollapse={toggleCollapse}
            />
         </div>
         <Collapse in={open} timeout="auto" sx={{ zIndex: 2 }} unmountOnExit>
            <div
               className={styles.fixturesList}
               style={{ background: 'transparent', display: 'flex', flexDirection: 'column', gap: '10px' }}
            >
               {renderBids()}
            </div>
         </Collapse>
      </div>
   );
};
