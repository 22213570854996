import React from 'react';
import styles from '../Secure.module.scss';
import { HomeOutlined, SportsSoccerOutlined, AccountCircleOutlined, EmojiEventsOutlined } from '@mui/icons-material';
import { FooterButton } from './FooterButton';

export const Footer: React.FC = () => {
   const footerButtons = [
      {
         text: 'Wall',
         icon: <HomeOutlined />,
         href: '/wall'
      },
      {
         text: 'Matches',
         icon: <SportsSoccerOutlined />,
         href: '/matches'
      },
      {
         text: 'Top',
         icon: <EmojiEventsOutlined />,
         href: '/top'
      },
      {
         text: 'Users',
         icon: <AccountCircleOutlined />,
         href: '/users'
      }
   ];

   return (
      <div className={styles.footerWrapper}>
         {footerButtons.map((fb: any, index: number) => (
            <FooterButton text={fb.text} href={fb.href} key={index}>
               {fb.icon}
            </FooterButton>
         ))}
      </div>
   );
};
