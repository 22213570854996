import React, { FC, useEffect, useState } from 'react';
import styles from '../Secure.module.scss';
import { Fixture } from '../../../types/DbModels';
import moment from 'moment';
import { FixtureDetailsSection } from './components/FixtureDetailsSection';
import { FixtureDetailsTeamCard } from './components/FixtureDetailsTeamCard';
import { useFetch, Methods } from '../../../hooks/useFetch';
import { useGlobalState } from '../../../context/GlobalStateContext';

interface FixtureProps {
   fixture: Fixture;
}

export type BidGames = {
   key: string;
   title: string;
   quote: number;
   result: number | null;
};

type BidConfiguration = {
   key: string;
   title: string;
   children: Array<BidGames>;
};

export type Ticket = {
   [key: string]: number;
};

export const FixtureDetails: FC<FixtureProps> = ({ fixture }) => {
   const { setInfo } = useGlobalState();

   const [ticket, setTicket] = useState<Ticket>({
      game1_1: fixture.game1_1_bid ? 1 : 0,
      game1_x: fixture.game1_x_bid ? 1 : 0,
      game1_2: fixture.game1_2_bid ? 1 : 0,
      game2_1: fixture.game2_1_bid ? 1 : 0,
      game2_2: fixture.game2_2_bid ? 1 : 0,
      game3_11: fixture.game3_11_bid ? 1 : 0,
      game3_1x: fixture.game3_1x_bid ? 1 : 0,
      game3_12: fixture.game3_12_bid ? 1 : 0,
      game3_x1: fixture.game3_x1_bid ? 1 : 0,
      game3_xx: fixture.game3_xx_bid ? 1 : 0,
      game3_x2: fixture.game3_x2_bid ? 1 : 0,
      game3_21: fixture.game3_21_bid ? 1 : 0,
      game3_2x: fixture.game3_2x_bid ? 1 : 0,
      game3_22: fixture.game3_22_bid ? 1 : 0,
      game4_gg: fixture.game4_gg_bid ? 1 : 0,
      game4_ng: fixture.game4_ng_bid ? 1 : 0
   });

   const { makeRequest: submitTicket } = useFetch(`/api/fixtures/ticket`, {
      method: Methods.POST,
      body: { ticket: ticket, fixture_id: fixture.id }
   });

   const bidConfigurations: Array<BidConfiguration> = [
      {
         key: 'game1',
         title: 'Match Outcome',
         children: [
            {
               key: 'game1_1',
               title: '1',
               quote: fixture.game1_1_quota,
               result: fixture.game1_1_result
            },
            {
               key: 'game1_x',
               title: 'x',
               quote: fixture.game1_x_quota,
               result: fixture.game1_x_result
            },
            {
               key: 'game1_2',
               title: '2',
               quote: fixture.game1_2_quota,
               result: fixture.game1_2_result
            }
         ]
      },
      {
         key: 'game2',
         title: 'Over / Under',
         children: [
            {
               key: 'game2_1',
               title: 'Under 2.5',
               quote: fixture.game2_1_quota,
               result: fixture.game2_1_result
            },
            {
               key: 'game2_2',
               title: 'Over 2.5',
               quote: fixture.game2_2_quota,
               result: fixture.game2_2_result
            }
         ]
      },
      {
         key: 'game3',
         title: 'Half Time / Full Time',
         children: [
            {
               key: 'game3_11',
               title: '1-1',
               quote: fixture.game3_11_quota,
               result: fixture.game3_11_result
            },
            {
               key: 'game3_xx',
               title: 'x-x',
               quote: fixture.game3_xx_quota,
               result: fixture.game3_xx_result
            },
            {
               key: 'game3_22',
               title: '2-2',
               quote: fixture.game3_22_quota,
               result: fixture.game3_22_result
            },
            {
               key: 'game3_1x',
               title: '1-x',
               quote: fixture.game3_1x_quota,
               result: fixture.game3_1x_result
            },
            {
               key: 'game3_x1',
               title: 'x-1',
               quote: fixture.game3_x1_quota,
               result: fixture.game3_x1_result
            },
            {
               key: 'game3_2x',
               title: '2-x',
               quote: fixture.game3_2x_quota,
               result: fixture.game3_2x_result
            },
            {
               key: 'game3_12',
               title: '1-2',
               quote: fixture.game3_12_quota,
               result: fixture.game3_12_result
            },
            {
               key: 'game3_x2',
               title: 'x-2',
               quote: fixture.game3_x2_quota,
               result: fixture.game3_x2_result
            },
            {
               key: 'game3_21',
               title: '2-1',
               quote: fixture.game3_21_quota,
               result: fixture.game3_21_result
            }
         ]
      },
      {
         key: 'game4',
         title: 'Both Teams to score',
         children: [
            {
               key: 'game4_gg',
               title: 'Yes',
               quote: fixture.game4_gg_quota,
               result: fixture.game4_gg_result
            },
            {
               key: 'game4_ng',
               title: 'No',
               quote: fixture.game4_ng_quota,
               result: fixture.game4_ng_result
            }
         ]
      }
   ];

   const handleTipClick = (game: BidGames) => {
      setTicket((prevTicket) => {
         const gamePrefix = game.key.split('_')[0];

         const updatedTicket = Object.fromEntries(
            Object.entries(prevTicket).map(([key, value]) => {
               if (key.startsWith(gamePrefix)) {
                  if (key === game.key) {
                     return [key, value === 1 ? 0 : 1];
                  }
                  return [key, 0];
               }
               return [key, value];
            })
         );

         return updatedTicket;
      });
   };

   const handleSubmitingTicket = async () => {
      await submitTicket();
      setInfo('Bet placed successfully!');
   };

   useEffect(() => {
      setTicket(() => {
         return {
            game1_1: fixture.game1_1_bid ? 1 : 0,
            game1_x: fixture.game1_x_bid ? 1 : 0,
            game1_2: fixture.game1_2_bid ? 1 : 0,
            game2_1: fixture.game2_1_bid ? 1 : 0,
            game2_2: fixture.game2_2_bid ? 1 : 0,
            game3_11: fixture.game3_11_bid ? 1 : 0,
            game3_1x: fixture.game3_1x_bid ? 1 : 0,
            game3_12: fixture.game3_12_bid ? 1 : 0,
            game3_x1: fixture.game3_x1_bid ? 1 : 0,
            game3_xx: fixture.game3_xx_bid ? 1 : 0,
            game3_x2: fixture.game3_x2_bid ? 1 : 0,
            game3_21: fixture.game3_21_bid ? 1 : 0,
            game3_2x: fixture.game3_2x_bid ? 1 : 0,
            game3_22: fixture.game3_22_bid ? 1 : 0,
            game4_gg: fixture.game4_gg_bid ? 1 : 0,
            game4_ng: fixture.game4_ng_bid ? 1 : 0
         };
      });
   }, [fixture]);

   return (
      <div className={styles.fixtureWrapper}>
         <div className={styles.fixtureInformationWrapper}>
            <div className={styles.fixtureInformationTime}>
               {fixture.date ? moment(fixture.date).format('ddd HH:mm DD-MM-YYYY') : 'N/A'}
               <br /> Stadium should go here
            </div>
            <div className={styles.fixtureInformationTeams}>
               <FixtureDetailsTeamCard logo={fixture.team_home_logo} name={fixture.team_home_name} />
               <div className={styles.fixtureInformationScore}>
                  {fixture.team_home_goals} : {fixture.team_away_goals}
                  {fixture.live ? (
                     <>
                        <div className={styles.fixtureInformationLive}>LIVE</div>
                        <div className={styles.fixtureInformationElapsed}>{fixture.elapsed}'</div>
                     </>
                  ) : null}
                  {fixture.finished ? (
                     <div className={styles.fixtureInformationScoreHalftime}>
                        {fixture.team_home_ht_goals} : {fixture.team_away_ht_goals}
                     </div>
                  ) : null}
               </div>
               <FixtureDetailsTeamCard logo={fixture.team_away_logo} name={fixture.team_away_name} />
            </div>
         </div>
         <div className={styles.fixtureMatchDetailsWrapper}>
            <div className={styles.fixtureMatchDetailsText}>Match Details</div>
         </div>
         <div className={styles.fixtureBiddingInformationWrapper}>
            {bidConfigurations.map((bc: BidConfiguration) => {
               const columns = bc.children.length > 3 ? 3 : bc.children.length;
               return (
                  <FixtureDetailsSection
                     columns={columns}
                     key={bc.key}
                     title={bc.title}
                     children={bc.children}
                     handleTipClick={handleTipClick}
                     ticket={ticket}
                     biddable={Boolean(fixture.biddable)}
                     finished={Boolean(fixture.finished)}
                  />
               );
            })}
         </div>
         {fixture.biddable ? (
            <button className={styles.fixtureBiddingSaveButton} onClick={handleSubmitingTicket}>
               Bet
            </button>
         ) : null}
      </div>
   );
};
